//@flow

// Vendors
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

// Graphics
import { ReactComponent as ServerErrorSvg } from '../../../../assets/server-error.svg';

// Components
import { Modal, Trans } from '../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { FormActions, FormActionsItem } from '../../Forms';
import { getCentralOffset } from '../../../../lib/datetime';

// Helpers
import { selectModalData } from '../../../../reducers/modal/modalSelectors';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './service-down-error-modal.scss';

const ServiceDownModal = () => {
  const dispatch = useDispatch();
  const pathArr = window.location.pathname.split('/');

  let agency;
  if (pathArr.includes('dmvrr') || pathArr.includes('dmv')) {
    agency = 'DMV';
  } else if (pathArr.includes('dps') || pathArr.includes('dpslr')) {
    agency = 'DPS';
  } else if (pathArr.includes('tdlr') || pathArr.includes('tdlrlr')) {
    agency = 'TDLR';
  } else if (pathArr.includes('tpwd') || pathArr.includes('tpwdas')) {
    agency = 'TPWD';
  }

  const errorCode = (useSelector(selectModalData) || {})['errorCode'];
  const errorMap = {
    MGMW_EX_4003: agency,
  };

  let errorCodeKey;
  let errorCodeValue;
  if (errorCode && Array.isArray(errorCode)) {
    [errorCodeKey, errorCodeValue] = errorCode;
  } else {
    errorCodeKey = errorCode;
  }

  const serviceDownName = errorMap[errorCodeKey];
  return (
    <Modal
      name="ServiceDownModal"
      className="modal-sm server-error-modal"
      disableOnClickOutside
      disableCloseButton
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Errors"
          id="ServiceDownModal_Title"
          fallabck="Houston, we have a problem."
        />
      </h2>
      <div className="modal-content">
        <div className="modal-graphic">
          <ServerErrorSvg role="presentation" />
        </div>
        {serviceDownName && (
          <>
            <>
              <p className="service-down-description">
                {serviceDownName}{' '}
                <Trans
                  file="Errors"
                  id="ServiceDownModal_Content"
                  fallback="services are unavailable at this time. Please try again after"
                />
                {` `}
                {errorCodeValue && (
                  <>
                    {errorCodeValue}
                    {` `}
                    {getCentralOffset()}
                  </>
                )}
              </p>
              <FormActions align="center">
                <FormActionsItem>
                  <ButtonPrimary
                    onClick={() => {
                      dispatch(closeModal('ServiceDownModal'));
                      dispatch(replace('/dashboard'));
                    }}
                  >
                    <Trans
                      file="Labels"
                      id="BackToDashboard"
                      fallback="Back to Dashboard"
                    />
                  </ButtonPrimary>
                </FormActionsItem>
              </FormActions>
            </>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ServiceDownModal;
