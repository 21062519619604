//@flow

// Vendors
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import Modal from '../Modal';
import {
  FormActions,
  FormActionsItem,
} from '../..';
import ServiceIconText from '../../ServiceIconText/ServiceIconText';
import Trans from '../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { TxButton, TxButtonMode, TxLoadingText } from 'texkit-ui/components';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';
import { pushMessageBanner } from '../../../../reducers/messageBanner/messageBannerActions';
import {
  unlinkAppLink,
  removeLinkFromState,
} from '../../../../reducers/agency/agencyActions';

// Helpers
import { selectModalData } from '../../../../reducers/modal/modalSelectors';

// Styles
import './TPWDAutoUnlink.scss';

// Switch to adjusted SCSS if on iOS device
var userAgent = window.navigator.userAgent.toLowerCase(),
  ios = /iphone|ipod|ipad/.test(userAgent);

const TPWDAutoUnlink = () => {
  const tpwdLinks = (useSelector(selectModalData) || {})['tpwdLinks'] || [];
  const moreAssets = (useSelector(selectModalData) || {})['moreAssets'] || false;
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(closeModal('TPWDAutoUnlink'));
    };
  }, [])

  const handleRemoveLinkClick = () => {
    setSubmitting(true);
    const messageIdTime = Date.now();

    const successBanner =
      `tpwd_NoMoreAgencyNotificationsMessageBanner`;

    tpwdLinks.forEach((link) => {
      const assetTypeLC = (link.mismatchAssetType || "").toLowerCase();
      const userKey = { userKey: link.userKey };
      dispatch
        (unlinkAppLink(userKey, '', true))
        .then(() => {
          dispatch(
            pushMessageBanner([
              {
                messageId: messageIdTime.toString(),
                textEnglish: `{"type": "Modals", "id": "${successBanner}", "args":"${assetTypeLC}"}`,
                textSpanish: `{"type": "Modals", "id": "${successBanner}", "args":"${assetTypeLC}"}`,
                startTime: '2021-01-01 18:35:00.0',
                endTime: '2040-06-30 12:05:00.0',
                priority: '4',
                status: 'R',
                author: 'USER',
                valid: true,
              },
            ])
          );
          dispatch(closeModal('TPWDAutoUnlink'));
          setTimeout(() => dispatch(removeLinkFromState(link), 200));
          setTimeout(() => {
            const banner = document.getElementById(
              `meesage-banner-${messageIdTime}`
            );
            if (banner) banner.click();
          }, 10000);
        })
        .catch(() => {
          dispatch(
            pushMessageBanner([
              {
                messageId: messageIdTime.toString(),
                textEnglish: `{"type": "Modals", "id": "tpwd_cantUnlink"}`,
                textSpanish: `{"type": "Modals", "id": "tpwd_cantUnlink"}`,
                startTime: '2021-01-01 18:35:00.0',
                endTime: '2040-06-30 12:05:00.0',
                priority: '1',
                status: 'R',
                author: 'USER',
                valid: true,
              },
            ])
          );
          setSubmitting(false);
        });
    })
  }
  return (
    <Modal name="TPWDAutoUnlink" disableCloseButton="true" disableOnClickOutside="true" className="tpwd-autounlink-modal">
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="TPWDAutoUnlink_Title" />
      </h2>
      <div className="modal-content">
        <p className="modal-subtitle auto-unlink-subtitle">
          <Trans file="Modals" id="TPWDAutoUnlink_SubTitle" />
        </p>
        <p className="modal-subtitle auto-unlink-txNumber">
          {tpwdLinks.map((link) => {
            return <ServiceIconText
              className="agency-linked-services-item-icon-text flex-item"
              type={ios ? `boat-plate-ios` : `boat-plate`}
              text={`TX ${link.mismatchAsset.substring(0, 4) + ' ' + link.mismatchAsset.substring(4)}`}
            />
          })}
        </p>
        <p className="modal-subtitle auto-unlink-subtitle-last">
          <Trans file="Modals" id="TPWDAutoUnlink_Desc" />
        </p>
        {!moreAssets ? <p className="modal-subtitle auto-unlink-subtitle-last">
          <Trans file="Modals" id="TPWDAutoUnlinkNoMoreAssets_Desc" />
        </p> : null}
        <FormActions align="center">
          <FormActionsItem>
            <TxButton
              className="ButtonDanger"
              onClick={handleRemoveLinkClick}
              mode={TxButtonMode.Danger}
            >
              {isSubmitting ? (
                <TxLoadingText>
                  <Trans file="Labels" id="Removing" fallback="Removing" />
                </TxLoadingText>
              ) : (
                <Trans file="Labels" id="Remove" />
              )}
            </TxButton>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
};

export default TPWDAutoUnlink;